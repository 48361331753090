body {
  overflow-x: hidden;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #99cfb9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #17b978;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #11915e;
}

.green-buttons {
  background-color: #17b978 !important;
  color: whitesmoke !important;
}
.green-buttons:hover {
  background-color: #0e8153 !important;
}

.blue-button {
  background-color: #007bff !important;
  color: whitesmoke !important;
}

.green-label {
  background-color: #17b978;
  color: whitesmoke;
  padding: 0.25rem 0.5rem;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}
.green-label:hover {
  background-color: #0e8153;
}

.project-label {
  display: inline-block;
  cursor: pointer;
}
.project-label:hover {
  text-decoration: underline;
}
